import React, { useCallback, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import * as Images from "../../../utilities/images";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addEvent, updateEvent } from "../../../redux/slices/web";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import moment from "moment/moment";
import { useWebSelector } from "../../../redux/selector/web";
import { useLocation, useNavigate } from "react-router-dom";

const AddEvent = () => {
  const location = useLocation();
  const { row } = location?.state || "";
  const eventselector = useWebSelector();
  const navigate = useNavigate();
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const [city, setCity] = useState(row?.location);
  const [file, setFile] = useState(row?.image);
  const [latitude, setLatitude] = useState(row?.latitude);
  const [longitude, setLongitude] = useState(row?.longitude);

  const timingRaw = row?.timing
    ? typeof row.timing === "string"
      ? JSON.parse(row.timing)
      : row.timing
    : {};

  const convertToDate = (dateString) => {
    // Check if dateString is defined and not empty
    if (!dateString) {
      return null; // or handle it however you want
    }

    // Replace the space between date and time with 'T' for ISO format
    const isoString = dateString.replace(" ", "T") + ":00"; // Append seconds
    return new Date(isoString);
  };

  const timing = {
    start:
      convertToDate(timingRaw?.start) ||
      convertToDate(timingRaw?.startTime) ||
      "", // Always use 'start'
    end:
      convertToDate(timingRaw?.end) || convertToDate(timingRaw?.endTime) || "", // Always use 'end'
  };

  const [startTime, setStartTime] = useState(timing.start ? timing.start : ""); // Convert start time);
  const [endTime, setEndTime] = useState(timing.end ? timing.end : "");

  // Single state for all form data
  const [formData, setFormData] = useState({
    eventTitle: row?.title,
    // date: row?.date ? moment(row.date).format("YYYY-MM-DD") : "",
    ticketPrice: row?.price,
    totalMembers: row?.total_numbers,
    totalMales: row?.male_count,
    totalFemales: row?.female_count,
    description: row?.description,
  });

  const handleTimeChange = (selectedDate, type) => {
    if (type === "startTime") {
      setStartTime(selectedDate);
      // Reset end time if it becomes invalid
      if (endTime && selectedDate > endTime) {
        setEndTime(null);
      }
    } else {
      setEndTime(selectedDate);
    }
  };

  // const getMinTime = () => {
  //   const now = new Date();
  //   return now.getDate() === startTime?.getDate() ? now : new Date(0, 0, 0, 0, 0);
  // };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const uploadedFile = acceptedFiles?.[0];

    const validTypes = ["image/jpeg", "image/png"];

    if (!validTypes.some((type) => uploadedFile.type.match(type))) {
      toast.error("Invalid file type.");
      return;
    }
    // const url = URL.createObjectURL(uploadedFile);
    setFile(uploadedFile);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    maxFiles: 1,
    disabled: typeof file == "boolean",
  });

  const autoCompleteHandleChange = (city) => {
    setCity(city);
    geocodeByAddress(city)
      .then((results) => {
        setLatitude(results[0].geometry.location.lat());
        setLongitude(results[0].geometry.location.lng());
      })
      .catch((error) => {});
  };

  // select city
  const autoCompleteHandleSelect = (city) => {
    getLocationFields(city);
  };

  // handle get location common function
  const getLocationFields = (city) => {
    geocodeByAddress(city)
      .then((results) => {
        setLatitude(results[0].geometry.location.lat());
        setLongitude(results[0].geometry.location.lng());
        if (results.length > 0) {
          // Extract the state from the results
          setCity(results[0].formatted_address);
        }
      })
      .catch((error) => {});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatDateTime = (startTime, endTime) => {
    return {
      startTime: moment(startTime).format("YYYY-MM-DD HH:mm"),
      endTime: moment(endTime).format("YYYY-MM-DD HH:mm"),
    };
  };

  const handleSubmit = () => {
    const {
      date,
      ticketPrice,
      totalMembers,
      totalMales,
      totalFemales,
      description,
      eventTitle,
    } = formData;

    if (!file) {
      showToast("Please upload image");
      return;
    } else if (!eventTitle) {
      showToast("Please enter event title");
      return;
    } else if (eventTitle.length < 5) {
      showToast("Please enter at least 5 characters in the title field");
      return;
    } else if (!city) {
      showToast("Please enter location");
      return;
    }
    // else if (!date) {
    //   showToast("Please select date");
    //   return;
    // }
    else if (!startTime) {
      showToast("Please select start time");
      return;
    } else if (!endTime) {
      showToast("Please select end time");
      return;
    }
    else if (!ticketPrice) {
      showToast("Please enter ticket price");
      return;
    } else if (ticketPrice && ticketPrice > 100000000) {
      showToast("Please enter a ticket price less than 100,000,000");
      return;
    } else if (!totalMembers) {
      showToast("Please enter total member");
      return;
    } else if (totalMembers && totalMembers > 100000000) {
      showToast("Please enter a total member less than 100,000,000");
      return;
    } else if (!totalMales) {
      showToast("Please enter total male");
      return;
    } else if (totalMales && totalMales > 100000000) {
      showToast("Please enter a total male less than 100,000,000");
      return;
    } else if (!totalFemales) {
      showToast("Please enter total Female");
      return;
    } else if (Number(totalMales) + Number(totalFemales) !== Number(totalMembers)) {
      showToast("The total count of male and female members must be exactly equal to the total number of members.");
      return;
    } else if (!description) {
      showToast("Please enter description");
      return;
    }

    const timing = formatDateTime(startTime, endTime);

    if (location?.state?.flag != "update") {
      let formDatas = new FormData();
      formDatas.append("image", file);
      formDatas.append("timing", JSON.stringify(timing));
      formDatas.append("description", description);
      formDatas.append("location", city);
      formDatas.append("longitude", longitude);
      formDatas.append("latitude", latitude);
      // formDatas.append("date", date);
      formDatas.append("total_numbers", totalMembers);
      formDatas.append("male_count", totalMales);
      formDatas.append("female_count", totalFemales);
      formDatas.append("total_numbers", totalMembers);
      formDatas.append("price", ticketPrice);
      formDatas.append("title", eventTitle);

      dispatch(
        addEvent({
          formDatas,
          cb(ress) {
            if (ress.status === 200) {
              navigate("/event");
            }
          },
        })
      );
    } else {
      let formDatas = new FormData();
      // Only append the image if `file` is a File instance
      if (file instanceof File) {
        formDatas.append("image", file);
      }
      formDatas.append("id", row?.id);
      formDatas.append("timing", JSON.stringify(timing));
      formDatas.append("description", description);
      formDatas.append("location", city);
      formDatas.append("longitude", longitude);
      formDatas.append("latitude", latitude);
      // formDatas.append("date", date);
      formDatas.append("total_numbers", totalMembers);
      formDatas.append("male_count", totalMales);
      formDatas.append("female_count", totalFemales);
      formDatas.append("total_numbers", totalMembers);
      formDatas.append("price", ticketPrice);
      formDatas.append("title", eventTitle);

      dispatch(
        updateEvent({
          formDatas,
          cb(ress) {
            if (ress.status === 200) {
              navigate("/event");
            }
          },
        })
      );
    }
  };

  return (
    <div className="content-wrapper">
      <section className="addEventSection">
        <h2 className="addEventTitle">{location?.state?.flag != "update"?"Add Event":"Update Event"}</h2>
        <div className="addEventContainer">
          <div className="container-fluid">
            <div className="eventImgContainer d-flex justify-content-center">
              {false ? (
                <div className="row">
                  <div className="col-sm-12 text-center pt-5 pb-5">
                    <div
                      className="spinner-grow text-success"
                      role="status"
                    ></div>
                  </div>
                </div>
              ) : (
                <div {...getRootProps()} className="dropzone ">
                  <input {...getInputProps()} />
                  <div className="uploadImg_Parent m-auto">
                    {file ? (
                      <div className="showImg_Parent">
                        <img
                          src={
                            file instanceof File
                              ? URL.createObjectURL(file)
                              : file
                          }
                          alt="Preview"
                          className="previewImg"
                        />
                        <i
                          className="fa-regular fa-trash-can  delete_Icon"
                          onClick={(event) => {
                            event.stopPropagation();
                            setFile("");
                          }}
                        ></i>
                      </div>
                    ) : (
                      <div className="text-center  uploadImgIcon">
                        <img src={Images.UploadImageIcon} alt="showImgLogo" />
                      </div>
                    )}
                    {file ? "" : <i class="fa-solid fa-upload fa-2xl"></i>}
                  </div>
                  <p className="text-center mt-2">Upload Image</p>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt">Event title</label>
                  <input
                    type="text"
                    className="inputBox"
                    name="eventTitle"
                    placeholder="Event title"
                    value={formData.eventTitle}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-6 selDateTime">
                <div className="form-group">
                  <label className="labelTxt">Location</label>
                  <PlacesAutocomplete
                    className=""
                    autoComplete="off"
                    value={city}
                    onChange={autoCompleteHandleChange}
                    onSelect={autoCompleteHandleSelect}
                    searchOptions={{
                      componentRestrictions: {
                        country: [],
                      },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "City",
                            className:
                              "location-search-input customform-control border-input inputBox",
                          })}
                        />
                        <div
                          className={
                            suggestions && suggestions.length > 0
                              ? "suggestion-item-outer"
                              : "autocomplete-dropdown-container"
                          }
                        >
                          {/* {/ {loading && <div>Loading...</div>}s /} */}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#e65c00",
                                  cursor: "pointer",
                                  borderRadius: "4px",
                                  padding: "6px",
                                  color: "#fff",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                key={index}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt"> Date </label>
                  <div className="selDateTime">
                    <DatePicker
                      name="date"
                      selected={formData.date}
                      onChange={handleDateChange}
                      placeholderText="Date"
                      dateFormat="MM/dd/yyyy"
                      minDate={new Date()}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt"> Start Date & Time </label>
                  <div className="datePickerWrapper">
                    <DatePicker
                      showTimeSelect
                      minDate={new Date()}
                      minTime={
                        startTime &&
                        startTime.toDateString() === new Date().toDateString()
                          ? new Date() // Restrict times before the current time if today
                          : new Date(0, 0, 0, 0, 0) // Allow any time on future dates
                      }
                      maxTime={new Date(0, 0, 0, 23, 59)} // End of the day
                      selected={startTime}
                      onChange={(date) => handleTimeChange(date, "startTime")}
                      dateFormat="yyyy/MM/dd HH:mm"
                      timeFormat="HH:mm"
                      placeholderText="Select start date and time"
                      className="inputBox position-relative"
                    />
                    <i className="far fa-clock time-icon dateTimeIcon"></i>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt"> End Date & Time </label>
                  <div className="datePickerWrapper">
                    <DatePicker
                      showTimeSelect
                      minDate={startTime || new Date()}
                      minTime={
                        startTime &&
                        endTime &&
                        startTime.toDateString() === endTime.toDateString()
                          ? startTime
                          : new Date(0, 0, 0, 0, 0)
                      }
                      maxTime={new Date(0, 0, 0, 23, 59)}
                      selected={endTime}
                      onChange={(date) => handleTimeChange(date, "endTime")}
                      dateFormat="yyyy-MM-dd HH:mm"
                      timeFormat="HH:mm"
                      placeholderText="Select end date and time"
                      className="inputBox position-relative"
                    />
                    <i className="far fa-clock time-icon dateTimeIcon"></i>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt">Ticket Price </label>
                  <input
                    type="text"
                    name="ticketPrice"
                    className="inputBox"
                    placeholder="Price"
                    value={formData.ticketPrice}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only positive numbers and up to two decimal places
                      const regex = /^[1-9]\d*(\.\d{0,2})?$/; // Adjusted regex for flexible input
                      if (value === "" || regex.test(value)) {
                        setFormData({
                          ...formData,
                          ticketPrice: value,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt">Total Members</label>
                  <input
                    type="text"
                    name="totalMembers"
                    className="inputBox"
                    placeholder="Members"
                    value={formData.totalMembers}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only positive numbers and up to two decimal places
                      const regex = /^[1-9]\d*(\.\d{0,2})?$/; // Adjusted regex for flexible input
                      if (value === "" || regex.test(value)) {
                        setFormData({
                          ...formData,
                          totalMembers: value,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt">Total Male</label>
                  <input
                    type="text"
                    name="totalMales"
                    className="inputBox"
                    placeholder="Male"
                    value={formData.totalMales}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only positive numbers and up to two decimal places
                      const regex = /^[1-9]\d*(\.\d{0,2})?$/; // Adjusted regex for flexible input
                      if (value === "" || regex.test(value)) {
                        setFormData({
                          ...formData,
                          totalMales: value,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="labelTxt">Total Female</label>
                  <input
                    type="text"
                    name="totalFemales"
                    className="inputBox"
                    placeholder="Female"
                    value={formData.totalFemales}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Allow only positive numbers and up to two decimal places
                      const regex = /^[1-9]\d*(\.\d{0,2})?$/; // Adjusted regex for flexible input
                      if (value === "" || regex.test(value)) {
                        setFormData({
                          ...formData,
                          totalFemales: value,
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="labelTxt">Description</label>
                  <textarea
                    className="inputBox"
                    name="description"
                    rows={4}
                    placeholder="Description here...."
                    value={formData.description}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <button className="saveBtn" onClick={handleSubmit}>
              {eventselector.loading ? "Loading" : "Save"}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddEvent;
