const ApiPath = {
  AuthApiPath: {
    ADMIN_LOGIN: "api/v1/admin/authenticate",
    FORGOT_PASSWORD: "/api/v1/admin/forget-password",
    RESET_PASSWORD: "api/v1/admin/reset-password",
    LOGOUT_ADMIN: "api/v1/admin/admin-logout",
  },
  webApiPath: {
    DASHBOARD_DATA: "/users/dashboard",
    GET_ALL_USER: "/api/v1/admin/get-all-users",
    GET_USER_INFO: "/api/v1/admin/get-user-by-id?user_id",
    GET_EVENT_INFO: "/api/v1/admin/get-event-by-id",
    ADD_USER: "/users",
    UPDATE_USER: "/users/update-user",
    UPDATE_USER_STATUS: "/api/v1/admin/verify-user",
    ACTIVE_INACTIVE_USER: "/api/v1/admin/activate-deactivate-user",
    ADD_IMAGE: "/api/v1/admin/add-event",
    GET_ALL_EVENTS: "/api/v1/admin/get-events-list",
    DELETE_EVENT: "/api/v1/admin/delete-event",
    UPDATE_EVENT: "/api/v1/admin/update-event",
    GET_CONTENT_INFO: "/api/v1/admin/get-page-by-page-type",
    UPDATE_CONTENT_INFO: "/api/v1/admin/update-page-content",
    VALIDATE_INVALIDATE_USER: "/api/v1/admin/validate-invalidate-user",
    USER_INTERACTIVE_SCORE: "/api/v1/admin/add-user-interactive-score",

    
  },
};

export default ApiPath;
