import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getOneEvent } from "../../../redux/slices/web";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../../redux/selector/web";
import { Spinner } from "react-bootstrap";

const ViewEvent = () => {
  const webData = useWebSelector();
  const { loading } = webData;
  const dispatch = useDispatch();
  const location = useLocation();
  const { row } = location?.state || "";
  const [eventData, setEventData] = useState("");
  console.log(eventData, "allEvents");

  // Parse the string to an object
  const timing =
    typeof eventData?.timing === "string"
      ? JSON.parse(eventData?.timing)
      : eventData?.timing;

  // Extract and format the start and end times
  // const startTime = new Date(
  //   timing?.start || timing?.startTime
  // ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

  // const endTime = new Date(timing?.end || timing?.endTime).toLocaleTimeString(
  //   [],
  //   { hour: "2-digit", minute: "2-digit" }
  // );

  // get all users
  const handleGetEvents = () => {
    let params = {
      id: row?.id,
    };
    dispatch(
      getOneEvent({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setEventData(res?.data?.data || "");
          }
        },
      })
    );
  };

  // get all event
  useEffect(() => {
    handleGetEvents();
  }, []);

  // useEffect(()=>{
  //   setEventData(location?.state)
  // },[])

  return (
    <div className="content-wrapper">
      <section className="addEventSection">
        <h2 className="addEventTitle">View Event Detsils</h2>
        <div className="addEventContainer">
          {loading ? (
            <div className="loaderContainer">
              <Spinner />
            </div>
          ) : (
            <div className="container-fluid">
              <div className="eventImgContainer ">
                <div className="uploadImg_Parent border_None">
                  <div className="showImg_Parent">
                    <img
                      src={eventData?.image}
                      alt="Preview"
                      className="previewImg "
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelTxt ms-0">Event title:</label>
                    <h5 className="event_InfoTxt">{eventData?.title}</h5>
                  </div>
                </div>
                <div className="col-md-6 selDateTime">
                  <div className="form-group">
                    <label className="labelTxt  ms-0">Location</label>
                    <h5 className="event_InfoTxt">{eventData?.location}</h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelTxt  ms-0"> Interest Users </label>
                    <h5 className="event_InfoTxt">
                      {eventData?.interest_users}
                    </h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelTxt  ms-0">
                      {" "}
                      Start Date & Time{" "}
                    </label>

                    <h5 className="event_InfoTxt">
                      {timing?.start || timing?.startTime}
                    </h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelTxt  ms-0"> End Time </label>

                    <h5 className="event_InfoTxt">
                      {timing?.end || timing?.endTime}
                    </h5>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelTxt  ms-0">Ticket Price </label>
                    <h5 className="event_InfoTxt">{eventData?.price}</h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelTxt  ms-0">Total Members</label>
                    <h5 className="event_InfoTxt">
                      {eventData?.total_numbers}
                    </h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelTxt  ms-0">Total Male</label>
                    <h5 className="event_InfoTxt">{eventData?.male_count}</h5>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="labelTxt  ms-0">Total Female</label>
                    <h5 className="event_InfoTxt">{eventData?.female_count}</h5>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label className="labelTxt  ms-0">Description</label>
                    <h5 className="event_InfoTxt">{eventData?.description}</h5>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ViewEvent;
