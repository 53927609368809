import React, { useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../../redux/selector/web";
import * as Images from "../../../utilities/images";
import {
  addUserInteractiveScore,
  getUserInfo,
  onErrorStopLoad,
} from "../../../redux/slices/web";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const ViewUser = () => {
  const location = useLocation();
  const { id } = location?.state || "";

  const dispatch = useDispatch();
  const toastId = useRef(null);
  const webData = useWebSelector();
  const { loading } = webData;

  const [getUserData, setGetUserData] = useState("");
  const [rating, setRating] = useState("");

  console.log(rating, "getColors");

  // Function to format date as DD/MM/YYYY
  const formatDate = (dob) => {
    const date = new Date(dob);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const questionsMap = [
    { id: 8, question: "What are you hoping to find?" },
    { id: 50, question: "Do you have kids?" },
    { id: 6, question: "Do you want kids?" },
    {
      id: 10,
      question: "How much time can you currently invest in a relationship?",
    },
    { id: 11, question: "What are your relationship must-haves?" },
    { id: 12, question: "What are your absolute dealbreakers?" },
    { id: 1, question: "Do you drink alcohol?" },
    { id: 2, question: "Do you smoke?" },
    { id: 13, question: "How physically active are you?" },
    { id: 14, question: "What’s your current living situation?" },
    { id: 3, question: "Religion" },
    { id: 4, question: "Politics" },
    {
      id: 15,
      question: "Are you open to polyamory or non-monogamous relationships?",
    },
    { id: 5, question: "Qualities you value in a person" },
    { id: 16, question: "What is your love language?" },
    { id: 17, question: "Are you more of an introvert or extrovert?" },
    {
      id: 18,
      question: "How often do you like to communicate in a relationship?",
    },
    { id: 9, question: "Your interests" },
    { id: 19, question: "How do you spend your free time?" },
    { id: 20, question: "Do you like to travel, and how often?" },
    { id: 21, question: "What’s your ideal travel style?" },
    { id: 22, question: "What’s your ideal date activity?" },
    { id: 7, question: "Causes and communities you care about" },
    { id: 23, question: "What's your go-to comfort food?" },
    {
      id: 24,
      question: "If you could instantly master one skill, what would it be?",
    },
    { id: 25, question: "What's the most adventurous thing you've ever done?" },
    { id: 26, question: "What’s a quirky habit you have?" },
    {
      id: 27,
      question: "What's one thing you absolutely cannot live without?",
    },
  ];

  //stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // view users info
  const handleUsersInfo = () => {
    let params = {
      user_id: id,
    };
    dispatch(
      getUserInfo({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            const resp = res?.data?.data;
            const updatedResult = {
              ...resp,
              interests: [
                ...resp?.interests,
                { type: 50, values: resp?.have_kids !== null ? [resp?.have_kids =="1"?"Yes":resp?.have_kids =="0"?"No":"Not answered" ]:"" },
              ],
            };
            setGetUserData(updatedResult);
            setRating(updatedResult?.interactive_score);
          }
        },
      })
    );
  };

  //add ratting
  const handleAddScrore = () => {
    let params = {
      user_id: id,
      score: rating,
    };
    dispatch(
      addUserInteractiveScore({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            handleUsersInfo();
          }
        },
      })
    );
  };

  useEffect(() => {
    handleUsersInfo();
  }, []);
  return (
    <>
      <div className="content-wrapper">
        <section className="addEventSection">
          <h2 className="addEventTitle">View User Details</h2>
          <div className="addEventContainer">
            <div className="container-fluid">
              <div className="create-user-outer">
                {loading ? (
                  <div className="loaderContainer">
                    <Spinner />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="mainProfileView">
                        <div className="text-center viewProfileImg">
                          <img
                            className="userViewImg"
                            src={getUserData?.profile_pic || Images.DummyImage}
                          />

                          <div className="inputFieldArea">
                            <div className="overallRating">
                              <label className="ratingLbl mb-0">Score:</label>
                              <input
                                type="number"
                                className="input-fileds ratingInputField"
                                name="rating"
                                placeholder="Add Score"
                                value={rating}
                                min={0}
                                onKeyDown={blockInvalidChar}
                                onChange={(e) => {
                                  const value = e.target.value;

                                  // Allow empty value to let the user delete input
                                  if (value === "") {
                                    setRating("");
                                    return;
                                  }

                                  // Convert value to a number
                                  const numericValue = Number(value);

                                  // Allow only numbers from 0 to 10
                                  if (
                                    !isNaN(numericValue) &&
                                    numericValue >= 0 &&
                                    numericValue <= 10
                                  ) {
                                    setRating(numericValue);
                                  }
                                }}
                              />
                            </div>

                            <button
                              className="saveBtn addProfileBtn"
                              onClick={handleAddScrore}
                            >
                              Add
                            </button>
                          </div>
                        </div>

                        <div className="profileInfoRow">
                          <div className="profileInformation">
                            <div className="mb-2 text-filed-outer">
                              <label className="modal-labels viewProfileInfoHeading">
                                <strong>Full Name</strong>
                              </label>
                              <p className="mb-0 fontSize16 fw500">
                                {getUserData?.name || "-----"}
                              </p>
                            </div>

                            <div className="mb-2 text-filed-outer">
                              <label className="modal-labels viewProfileInfoHeading">
                                <strong>Phone No.</strong>
                              </label>
                              <p className="mb-0 fontSize16 fw500">
                                {getUserData?.phone_number}
                              </p>
                            </div>

                            <div className="mb-2 text-filed-outer">
                              <label className="modal-labels viewProfileInfoHeading">
                                <strong>Date of birth</strong>
                              </label>
                              <p className="mb-0 fontSize16 fw500">
                                {getUserData.dob
                                  ? formatDate(getUserData.dob)
                                  : "-----"}
                              </p>
                            </div>

                            <div className="mb-2 text-filed-outer">
                              <label className="modal-labels viewProfileInfoHeading">
                                <strong>Status</strong>
                              </label>
                              <p className="mb-0 fontSize16 fw500">
                                {getUserData?.is_admin_verified === 0
                                  ? "Pending"
                                  : getUserData?.is_admin_verified === 1
                                  ? "Accept"
                                  : getUserData?.is_admin_verified === 2
                                  ? "Reject"
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="usrQuesParentBox">
                          <div className="text-filed-outer row">
                            {questionsMap.map(({ id, question }) => {
                              // Find the matching interest based on `id`
                              const interest = getUserData?.interests?.find(
                                (item) => item?.type === id
                              );

                              console.log(interest,"interest");
                              

                              // Extract values, ensuring they are displayed correctly
                              const answer =
                                interest?.values?.length > 0 
                                  ? interest.values.join(", ")
                                  : "Not answered";

                              return (
                                <div key={id} className="col-md-6">
                                  <div className="mainViewQues">
                                    <p className="mb-0 viewProfileInfoHeading">
                                      <strong>{question}</strong>
                                    </p>
                                    <p className="mb-0">{answer}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ViewUser;
