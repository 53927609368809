import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import {
  onErrorStopLoad,
  setgetDashBoardData,
  setgetAllUsers,
  setAddUsers,
  setUpdateUsers,
  setUpdateUserStatus,
  setGetUserInfo,
  setActiveInactiveUser,
  setAddEvent,
  setGetAllEvents,
  setDeleteEvent,
  setUpdateEvent,
  setGetContentPagesInfo,
  setUpdateContentPagesInfo,
  setUpdatevalidateUser,
  setAddUserInteractiveScore,
} from "../../slices/web";

const queryString = require("query-string");

function* updateUserStatus(action) {

  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.UPDATE_USER_STATUS}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpdateUserStatus(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.dismiss();
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* validateInvalidateUser(action) {
  try {
    const { cb, ...payloadWithoutCb } = action.payload;

    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.VALIDATE_INVALIDATE_USER}`),
      payloadWithoutCb
    );
    if (resp.status) {
      yield put(setUpdatevalidateUser(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.dismiss();
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* addUserInteractiveScore(action) {

  try {
    const { cb, ...payloadWithoutCb } = action.payload;
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.USER_INTERACTIVE_SCORE}`),
      payloadWithoutCb
    );
    if (resp.status) {
      yield put(setAddUserInteractiveScore(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.dismiss();
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* updateUsers(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.webApiPath.UPDATE_USER}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setUpdateUsers(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* addUsers(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.ADD_USER}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setAddUsers(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getDashBoardData(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.DASHBOARD_DATA}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setgetDashBoardData(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getAllUsers(action) {
  try {
    let url = `${ApiPath.webApiPath.GET_ALL_USER}`;
    if (action.payload.page && action.payload.perPage) {
      url += `?page=${action.payload.page}&perPage=${action.payload.perPage}`;
    }
    if (action.payload.search) {
      url += `&search=${action.payload.search}`;
    }
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setgetAllUsers(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getUserInfo(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_USER_INFO}=${action.payload.user_id}`)
    );
    if (resp.status) {
      yield put(setGetUserInfo(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* activeInactiveUser(action) {

  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.ACTIVE_INACTIVE_USER}`),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setActiveInactiveUser(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* addEvent(action) {
  
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.ADD_IMAGE}`),
   action.payload.formDatas
    );
    if (resp.status) {
      yield put(setAddEvent(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getAllEvents(action) {

  try {
    let url = `${ApiPath.webApiPath.GET_ALL_EVENTS}`;
    if (action.payload.page && action.payload.perPage) {
      url += `?page=${action.payload.page}&perPage=${action.payload.perPage}`;
    }
    if (action.payload.search) {
      url += `&search=${action.payload.search}`;
    }
    const resp = yield call(
      ApiClient.get,
      (action.url = url),
      (action.payload = action.payload)
    );

    if (resp.status) {
      yield put(setGetAllEvents(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.message);
  }
}
function* deleteEvent(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.webApiPath.DELETE_EVENT}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setDeleteEvent(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getOneEvent(action) {
  console.log(action,"ASASAsAS");
  
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_EVENT_INFO}/${action.payload.id}`)
    );
    if (resp.status) {
      yield put(setGetUserInfo(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* updateEvent(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.webApiPath.UPDATE_EVENT}`
      ),
      action.payload.formDatas
    );
    if (resp.status) {
      yield put(setUpdateEvent(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* getContentPagesInfo(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.webApiPath.GET_CONTENT_INFO}/${action.payload.type}`)
    );
    if (resp.status) {
      yield put(setGetContentPagesInfo(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}
function* updateContentPagesInfo(action) {
  try {
    const { id, description, cb } = action.payload;
    const resp = yield call(
      ApiClient.post,
      `${ApiPath.webApiPath.UPDATE_CONTENT_INFO}/${id}`, // Send id as part of the URL
      { description } // Send description in the body of the request
    );
    if (resp.status) {
      yield put(setUpdateContentPagesInfo(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.message);
  }
}

function* webSaga() {
  yield all([takeLatest("web/getDashBoardData", getDashBoardData)]);
  yield all([takeLatest("web/getAllUsers", getAllUsers)]);
  yield all([takeLatest("web/addUsers", addUsers)]);
  yield all([takeLatest("web/updateUsers", updateUsers)]);
  yield all([takeLatest("web/updateUserStatus", updateUserStatus)]);
  yield all([takeLatest("web/getUserInfo", getUserInfo)]);
  yield all([takeLatest("web/activeInactiveUser", activeInactiveUser)]);
  yield all([takeLatest("web/addEvent", addEvent)]);
  yield all([takeLatest("web/getAllEvents", getAllEvents)]);
  yield all([takeLatest("web/deleteEvent", deleteEvent)]);
  yield all([takeLatest("web/getOneEvent", getOneEvent)]);
  yield all([takeLatest("web/updateEvent", updateEvent)]);
  yield all([takeLatest("web/getContentPagesInfo", getContentPagesInfo)]);
  yield all([takeLatest("web/updateContentPagesInfo", updateContentPagesInfo)]);
  yield all([takeLatest("web/updatevalidateUser", validateInvalidateUser)]);
  yield all([takeLatest("web/addUserInteractiveScore", addUserInteractiveScore)]);
  
  

}

export default webSaga;
