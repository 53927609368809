import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../layout/DashboardLayout";
import AuthLayout from "../layout/AuthLayout";
import * as Containers from "../containers";
import PublicRoute from "../layout/PublicRoute";
import ProtectRoute from "../layout/ProtectRoute";

const Router = () => {
  return (
    <>
      <Routes>
        {/* DASHBOARD_ROUTES */}
        <Route element={<DashboardLayout />}>
          <Route
            path="/dashboard"
            element={
              <ProtectRoute>
                <Containers.Dashboard />
              </ProtectRoute>
            }
          />
          <Route
            path="/user"
            element={
              <ProtectRoute>
                <Containers.Users />
              </ProtectRoute>
            }
          />
          <Route
            path="/event"
            element={
              <ProtectRoute>
                <Containers.Events />
              </ProtectRoute>
            }
          />
          <Route
            path="/addEvent"
            element={
              <ProtectRoute>
                <Containers.AddEvent />
              </ProtectRoute>
            }
          />

          <Route
            path="/viewEvent"
            element={
              <ProtectRoute>
                <Containers.ViewEvent />
              </ProtectRoute>
            }
          />
          <Route
            path="/viewUser"
            element={
              <ProtectRoute>
                <Containers.ViewUser />
              </ProtectRoute>
            }
          />
          <Route
            path="/contentPages"
            element={
              <ProtectRoute>
                <Containers.ContentPage />
              </ProtectRoute>
            }
          />
        </Route>

        {/* AUTH_ROUTES */}
        <Route element={<AuthLayout />}>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Containers.Login />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <Containers.ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute>
                <Containers.ResetPassword />
              </PublicRoute>
            }
          />

          <Route path="*" element={<Containers.PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default Router;
