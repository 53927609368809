import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    updateUserStatus: (state) => {
      state.loading = true;
    },
    setUpdateUserStatus: (state) => {
      state.loading = false;
    },
    updateUsers: (state) => {
      state.loading = true;
    },
    setUpdateUsers: (state) => {
      state.loading = false;
    },
    addUsers: (state) => {
      state.loading = true;
    },
    setAddUsers: (state) => {
      state.loading = false;
    },
    getDashBoardData: (state) => {
      state.loading = true;
    },
    setgetDashBoardData: (state) => {
      state.loading = false;
    },
    getAllUsers: (state) => {
      state.loading = true;
    },
    setgetAllUsers: (state) => {
      state.loading = false;
    },
    getUserInfo: (state) => {
      state.loading = true;
    },
    setGetUserInfo: (state) => {
      state.loading = false;
    },
    activeInactiveUser: (state) => {
      state.loading = true;
    },
    setActiveInactiveUser: (state) => {
      state.loading = false;
    },
    addEvent: (state) => {
      state.loading = true;
    },
    setAddEvent: (state) => {
      state.loading = false;
    },
    getAllEvents: (state) => {
      state.loading = true;
    },
    setGetAllEvents: (state) => {
      state.loading = false;
    },
    deleteEvent: (state) => {
      state.loading = true;
    },
    setDeleteEvent: (state) => {
      state.loading = false;
    },
    getOneEvent: (state) => {
      state.loading = true;
    },
    setGetOneEvent: (state) => {
      state.loading = false;
    },
    updateEvent: (state) => {
      state.loading = true;
    },
    setUpdateEvent: (state) => {
      state.loading = false;
    },
    getContentPagesInfo: (state) => {
      state.loading = true;
    },
    setGetContentPagesInfo: (state) => {
      state.loading = false;
    },
    updateContentPagesInfo: (state) => {
      state.loading = true;
    },
    setUpdateContentPagesInfo: (state) => {
      state.loading = false;
    },
    updatevalidateUser: (state) => {
      state.loading = true;
    },
    setUpdatevalidateUser: (state) => {
      state.loading = false;
    },
    addUserInteractiveScore: (state) => {
      state.loading = true;
    },
    setAddUserInteractiveScore: (state) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onErrorStopLoad,
  getDashBoardData,
  setgetDashBoardData,
  getAllUsers,
  setgetAllUsers,
  addUsers,
  setAddUsers,
  updateUsers,
  setUpdateUsers,
  updateUserStatus,
  getUserInfo,
  setGetUserInfo,
  setUpdateUserStatus,
  activeInactiveUser,
  setActiveInactiveUser,
  addEvent,
  setAddEvent,
  getAllEvents,
  setGetAllEvents,
  deleteEvent,
  setDeleteEvent,
  getOneEvent,
  setGetOneEvent,
  updateEvent,
  setUpdateEvent,
  getContentPagesInfo,
  setGetContentPagesInfo,
  updateContentPagesInfo,
  setUpdateContentPagesInfo,
  updatevalidateUser,
  setUpdatevalidateUser,
  addUserInteractiveScore,
  setAddUserInteractiveScore,
} = webSlice.actions;

export default webSlice.reducer;
